//const devInst = 'kevin'
//const devInst = 'kabine'
//const devInst = 'showcase'
//const devInst = 'oebb'
//const devInst = 'railscreen'
const devInst = 'marty'
//const devInst = 'immensus'

function resourceUrl() {
  let hostname = window.location.hostname

  if(process.env.NODE_ENV !== 'production') {
    switch(devInst) {
    case 'kabine':
      hostname = 'kabine.ziicon.com'
      break
    case 'kevin':
      hostname = 'kevin.ziicon-media.com'
      break
    case 'marty':
      hostname = 'marty.ziicon.com'
      break
    case 'immensus':
      hostname = 'martymedia.imm.solutionsca.pl'
      break
    case 'railscreen':
      hostname = 'railscreen.ziicon-media.com'
      break
    case 'showcase':
      hostname = 'showcase.ziicon.com'
      break
    case 'oebb':
      hostname = 'oebb.ziicon.com'
      break
    default:
      throw new Error('resourceUrl - no development instance configured')
    }
  }

  return window.location.protocol + '//' + hostname
}


export function backendUrl() {
  if(process.env.NODE_ENV === 'production')
    return window.location.protocol + '//' + window.location.hostname + '/api'
  else if(devInst === 'immensus')
    return immensusUrl()
  else
    return '//localhost:' + devInstPort()
}


export function immensusUrl() {
  //return 'https://martymedia.imm.solutionsca.pl/api'
  return ''
}


export function usesImmensus() {
  return backendUrl() === immensusUrl()
}


export function spotUrl(spot, isThumbnail) {
  const thumbnail = isThumbnail !== undefined && isThumbnail
  const path = thumbnail ? '/uploads/thumbnails/' : '/uploads/data/'
  const parts = spot.split('.')
  const ext = thumbnail ? 'jpg' : parts[1]
  return resourceUrl() + path + parts[0] + '.' + ext
}


export function bookingProofUrl(fileName) {
  return resourceUrl() + '/uploads/booking_proof/' + fileName
}


export function landingPageUrl() {
  const parts = window.location.href.split("/")
  return parts[0] + '//' + parts[2]
}


function devInstPort() {
  switch(devInst) {
  case 'kabine':
    return 8084
  case 'kevin':
    return 8080
  case 'marty':
    return 8083
  case 'railscreen':
    return 8081
  case 'showcase':
    return 8085
  case 'oebb':
    return 8086
  default:
    throw new Error('devInstPort - no development instance configured')
  }
}
