import { axiosInstance, ApiError } from '../components/shared/request'


export function getCsrfToken() {
  const value = `; ${document.cookie}`
  const parts = value.split(`; csrftoken=`)
  return parts.length === 2 ?  parts.pop().split(';').shift() : ''
}


export async function refreshCsrfToken() {
  try {
    document.cookie = 'csrftoken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;'

    const options = {
      url: '/api-auth/login/',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    await axiosInstance.request(options)
  } catch(error) {
    throw new ApiError(error.toString(), error, 'token refresh failed')
  }
}


export function immensusPath(path, options) {
  const p = (path.startsWith('/') ? '' : '/') + path
  switch(p) {
  case '/login':
    return '/api/auth/'
  case '/userdata':
    return '/api/user-info/'
  case '/admin/sites':
    return '/api/location/' + tail(options)
  case '/admin/users':
    return '/api/user/' + tail(options)
  default:
    throw new ApiError('Unsupported Immensus path: ' + p, null, 'immensusPath failed')
  }
}


export function toImmensus(path, options) {
  if(!options.hasOwnProperty('data')) {
    return options
  } else {
    const data = { ...options.data }

    const p = (path.startsWith('/') ? '' : '/') + path
    switch(p) {
    case '/login':
      renameProp(data, 'email', 'username')
      data.password = options.data.password
      data.csrfmiddlewaretoken = getCsrfToken()
      data.next = '?next=/api/'
      break

    case '/admin/sites':
      mergeProps(data, ['postcode', 'city'], 'city')
      deleteProps(data, ['block_size', 'num_blocks', 'loop_length_secs', 'price_per_second', 'province', 'country', 'info', 'data_devices_count'])
      data.regon = null
      data.phone = null
      data.url = null
      data.contact_manager_name = null
      data.contact_manager_phone = null
      data.contact_manager_email = null
      data.contact_service_name = null
      data.contact_service_phone = null
      data.contact_service_email = null
      break

    default:
      break
    } 

    return {
      ...options,
      data,
    }
  }
}


export function fromImmensus(path, method, data) {
  const p = (path.startsWith('/') ? '' : '/') + path

  if(method !== 'GET') {
    data.error = ''
    return data
  }

  switch(p) {
  case '/login':
    data.token = getCsrfToken()
    return data
  case '/userdata':
    data.admin = 1
    data.agent = 0
    data.client_group_id = 8
    data.gender = 'neutral'
    data.dashboardsize = 1
    data.dashboardpriorities = 7
    data.navigationsize = 1
    data.permission_view = 14680063
    data.permission_add = 14680063
    data.permission_edit = 14680063
    data.permission_delete = 14680063
    data.access_flags = 0
    data.preferences = 1
    data.state = data.is_active ? 'active' : 'deleted'
    return data

  case '/admin/sites':
    return data.results.map((site) => {
      const city = site.city ? site.city.split(' ') : ['', '']
      return {
        ...site,
        info: '',
        country: 'Austria',
        postcode: 1 < city.length ? city[0] : '',
        province: '',
        address: site.address ? site.address : '',
        city: 1 < city.length ? city[1] : city[0],
        price_per_second: 0.1,
        block_size: 20,
        num_blocks: 1,
        loop_length_secs: 90,
        state: site.is_active ? 'active' : 'deleted',
      }
    })

  default:
    return data
  }
}


function renameProp(data, src, dst) {
  data[dst] = data[src]
  delete data[src]
}


function mergeProps(data, src, dst) {
  var merged = ''
  src.forEach((s, i) => {
    merged += data[s]
    if(i < src.length - 1)
      merged += ' '
    if(s !== dst)
      delete data[s]
  })
  data[dst] = merged
}


function deleteProps(data, props) {
  props.forEach((p) => delete data[p])
  delete data.index
  delete data._id
  if(data.hasOwnProperty('state') && data.hasOwnProperty('is_active')) {
    data.is_active = data.state === 'active'
    delete data.state
  }
}


function tail(options) {
  return options.method === 'PUT' ? `${options.data.id}/` : ''
}
