/*

A new theme can be defined by adding a new entry to themes, where the name of
the entry matches the name of the instance, i.e. railscreen for
railscreen.ziicon.com.

Only settings that over-write the defaultTheme need to be set for the new theme.

In addition, the logo must be placed under ziicms/src/images/<instance>.png. It
must also be added in src/components/nav/HeaderLogo.js to the imports at the top
and in the switch for mapping the instance to the logo.

For testing of the new theme under localhost, localhostInstance() can be
modified below to return the desired <instance>.

For testing of a clone on the server, instance() must map the subdomain of the
test instance to the desired <instance> that was cloned.

*/

import React from "react";
import { ThemeProvider } from "styled-components";
import { backendUrl, immensusUrl } from "./utils/UrlHelper";

const localhostInstance = () => {
  switch(backendUrl()) {
  case '//localhost:8080':
    return 'railscreen'
  case '//localhost:8081':
    return 'railscreen'
  case '//localhost:8082':
    return 'epamedia'
  case '//localhost:8083':
    return 'marty'
  case '//localhost:8084':
    return 'marty'
  case immensusUrl():
    return 'marty'
  case '//localhost:8085':
    return 'showcase'
  default:
    return 'railscreen'
  }
  /*
    return 'epamedia'
    return 'marty'
  */
}

const themes = {
  railscreen: {
    // Identical to defaultTheme
  },

  epamedia: {
    colors: {
      themeBackground: "#FFE600",
      themeHighlightColor: "#FFE600",
      themeColor: "#000000",
      themeHeaderBackground: "#0000008C",
      themeHeaderColor: "#EEE",
    },
    header: {
      height: "58px",
      logoMarginTop: "10px",
      logoMarginBottom: "16px",
      logoMarginLeft: "20px",
    },
    pdf: {
      logoWidth: 100,
      lineColor: "#FFF",
      tableColor: "#000",
    },
  },

  marty: {
    colors: {
      //themeBackground: "#FFE600",
      //themeColor: "#333",
      //themeBackground: "#7F8087",
      themeBackground: "#454545",
      themeHighlightColor: "#FFED00",
      //green: "#157946",
      themeHeaderBackground: "#222",
      themeHeaderColor: "#FFF",
    },
    header: {
      height: "58px",
      logoMarginTop: "0px",
      logoMarginBottom: "0px",
      logoMarginLeft: "44px",
      //logoMarginTop: "10px",
      //logoMarginBottom: "10px",
      //logoMarginLeft: "24px",
    },
    pdf: {
      logoWidth: 100,
      lineColor: "#FFF",
      tableColor: "#000",
    },
  },

  showcase: {
//    Max5 Theme
//    colors: {
//      themeBackground: "#2C2C2C",
//      themeHighlightColor: "#5946B2",
//      themeHeaderBackground: "#3C3C3C",
//      themeHeaderColor: "#FFF",
//      green: "#07D01D",
//    },
//    header: {
//      height: "58px",
//      logoMarginTop: "12px",
//      logoMarginBottom: "12px",
//      logoMarginLeft: "56px",
//    },
//    pdf: {
//      logoWidth: 100,
//      lineColor: "#FFF",
//      tableColor: "#000",
//    },
  },

  spar: {
    colors: {
      themeBackground: "#EC1B24",
      themeHighlightColor: "#EC1B24",
      green: "#157946",
    },
    header: {
      height: "58px",
      logoMarginTop: "14px",
      logoMarginBottom: "14px",
      logoMarginLeft: "56px",
    },
    pdf: {
      logoWidth: 100,
      lineColor: "#FFF",
      tableColor: "#000",
    },
  },
};


const defaultTheme = {
  colors: {
    themeBackground: "#E2002A",
    themeColor: "#FFFFFF",
    themeHighlightColor: "#E2002A",
    themeHeaderBackground: "#FFF",
    themeHeaderColor: "#000",
    themeNavBackground: "rgba(0, 0, 0, 0.2)",
    themeNavColor: "#FFFFFF",
    themeSelectedBackground: "rgba(0, 0, 0, 0.5)",
    themeSpotBackground: "#20B8F9",
    lightBackground: "#FFFFFF",
    lightColor: "#111111",
    darkBackground: "#000000",
    darkColor: "#FFFFFF",
    black: "#000",
    green: "#45CB41",
    grey: "#CCC",
    lightgrey: "#AAA",
    darkgrey: "#333",
    orange: "#F80",
    red: "#E2002A",
    yellow: "#FF0",
    white: "#FFF",
  },
  fonts: {
    light: "Roboto-Light",
    medium: "Roboto-Medium",
    bold: "Roboto-Bold",
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  header: {
    height: "58px",
    logoMarginTop: "0px",
    logoMarginBottom: "0px",
    logoMarginLeft: "0px",
  },
  bookingUnit: {
    width: "300px",
  },
  pdf: {
    logoWidth: 38,
    lineColor: "#E2002A",
    tableColor: "#E2002A",
  },
  table: {
    idxColWidth: "30px",
    actionColWidth: "100px",
  },
};


const mergedTheme = () => {
  const i = instance()

  return {
    colors: {...defaultTheme.colors, ...themes[i].colors},
    fonts: {...defaultTheme.fonts, ...themes[i].fonts},
    fontSizes: {...defaultTheme.fontSizes, ...themes[i].fontSizes},
    bookingUnit: {...defaultTheme.bookingUnit, ...themes[i].bookingUnit},
    header: {...defaultTheme.header, ...themes[i].header},
    pdf: {...defaultTheme.pdf, ...themes[i].pdf},
    table: {...defaultTheme.table, ...themes[i].table},
  }
};


const parseRGB = (color, idx) => {
  const three = color.length === 4
  const i = three ? idx + 1 : 2 * idx + 1
  const j = three ? i + 1 : i + 2
  const val = parseInt(color.substring(i, j), 16)
  return three ? val * 16 + val : val
};


const instance = () => {
  const inst = window.location.hostname.split('.')[0]
  switch(inst) {
  case 'localhost':
    return localhostInstance()
  case 'kevin':
    return 'railscreen'
  case 'kabine':
  case 'immensus':
    return 'marty'
  case 'oebb':
    return 'railscreen'
  default:
    return inst
  }
};


export function instanceOneOf() {
  for(var i = 0; i < arguments.length; i++) {
    if(arguments[i] === instance())
      return true
  }

  return false
}

export function instanceConfig() {
  const theme = mergedTheme()

  return {
    instance: instance(),
    logo_width: theme.pdf.logoWidth,
    line_r: parseRGB(theme.pdf.lineColor, 0),
    line_g: parseRGB(theme.pdf.lineColor, 1),
    line_b: parseRGB(theme.pdf.lineColor, 2),
    table_r: parseRGB(theme.pdf.tableColor, 0),
    table_g: parseRGB(theme.pdf.tableColor, 1),
    table_b: parseRGB(theme.pdf.tableColor, 2),
  }
};


export const cBlocksOfPlayers = instanceOneOf('marty')
export const cLoopLength = instanceOneOf('marty')
export const cDefaultSpotLength = instanceOneOf('marty') ? 10 : 10
export const cAllowedSpotLengths = [6, cDefaultSpotLength]
export const cDefaultSector = instanceOneOf('marty')
export const cExtendedCustomer = instanceOneOf('marty')
export const cFixedPricePerSecond = false //instanceOneOf('marty')
export const cPricingUnit = instanceOneOf('marty') ? 'week' : 'second'
export const cImplicitPlayers = true //instanceOneOf('marty', 'showcase')
export const cHourlyResolution = !instanceOneOf('marty')
export const cDailyResolution = true //!instanceOneOf('marty')
export const cSpotFees = false //instanceOneOf('marty')
export const cNewStandardBooking = !instanceOneOf('marty')
export const cNewFillinBooking = !instanceOneOf('marty')
export const cNewVacancyBooking = true
export const cInvoicingEnabled = false //instanceOneOf('marty')
export const cShowPreviousSpots = instanceOneOf('marty')
export const cTutorialEnabled = instanceOneOf('marty', 'showcase')
export const cZonesEnabled = instanceOneOf('marty')
export const cZoneLabels = ['Zone 0 - Cashier', 'Zone 1 - Fresh', 'Zone 2 - Serviced', 'Zone 3 - Alcohol', 'Zone 4 - Drinks', 'Zone 5 - General', 'zone 6', 'zone 7', 'zone 8', 'zone 9', 'zone 10']
export const cZonesAllMask = (1 << cZoneLabels.length) - 1


const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={mergedTheme()}>
      {children}
    </ThemeProvider>
  )
};

export default Theme;
