import React from 'react'
import { StyledText } from '../components/styling/TextStyling'

export const majorVersion = () => 2
export const minorVersion = () => 2
export const serviceVersion = () => 8

export const versionStr = () => 'v' + majorVersion() + '.' + minorVersion() + '.' + serviceVersion()

export const VersionElement = (props) => {
  const style = {
    position: 'absolute',
    right: props.auth ? 12 : 18,
    bottom: 8
  }

  return (
    <StyledText
      fontSize={9}
      style={style}
    >
      {versionStr()}
    </StyledText>
  )
}
