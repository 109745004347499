import Axios from 'axios'

import { getAuthToken } from '../auth/Authentication'

import { getCsrfToken, refreshCsrfToken, immensusPath, toImmensus, fromImmensus } from '../../utils/Immensus'
import { backendUrl, usesImmensus } from '../../utils/UrlHelper'
import { versionStr } from '../../utils/Version'


export const axiosInstance = Axios.create({
  //baseURL: '/api'
});


/**
 * request function
 * @param {string} path API Endpoint to fetch
 * @param {object} userOptions User options for method, baseUrl, body, headers etc..
 */
export default async function request(path, userOptions = {}) {
  if(usesImmensus() && path === '/login')
    await refreshCsrfToken()

  const defaultOptions = !usesImmensus() ? {
    url: path,
    baseURL: backendUrl(),
    method: 'GET',
  } : {
    url: immensusPath(path, userOptions),
    method: 'GET',
    withCredentials: true,
  }

  const defaultHeaders = !usesImmensus() ? {
    Version: versionStr(),
    Authorization: getAuthToken(),
  } : {
    'X-CSRFToken': getCsrfToken(),
    'Accept': 'application/json',
  }

  const options = {
    ...defaultOptions,
    ...(!usesImmensus() ? userOptions : toImmensus(path, userOptions)),
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
    // Decode JSON timestamps to Javascript Date
    // See https://github.com/axios/axios#request-config
    transformResponse: transformResponse,
    // Encode Javascript Date to UTC timestamp: Remove the timezone, because 
    // the players should play it at local time identical to browser time.
    // We currently use Date.Prototype.toJSON instead - not sure if this is
    // safe or conflicts with the underlying library. TODO - Check this.
    //transformRequest: [transformRequest, ...Axios.defaults.transformRequest],
  }

  if(false && options.method === 'PUT')
    debugger
  if(process.env.NODE_ENV !== 'production')
    console.log('API call with options ', options)
  else
    console.log('API call with options ', { ...defaultOptions, ...userOptions })

  return axiosInstance.request(options)
    .then((response) => {
      if(usesImmensus() && response.request.responseURL.endsWith(immensusPath('/userdata')) && response.data.id === null)
        throw new ApiError("Unauthenticated", response, 403);

      return !usesImmensus() ? response.data : fromImmensus(path, options.method, response.data);
    })
    .catch((error) => {
      // Throw custom API error
      // If response exists it means HTTP error occured
      if(error.response) {
        if(error.response.status === 426) {
          window.location.reload(true);
          throw new ApiError("Force browser reload", null, "REQUEST_FAILED");
        } else {
          throw new ApiError(
            `Request failed with status ${error.response.status}.`,
            error,
            error.response.status
          );
        }
      } else {
        throw new ApiError(error.toString(), null, "REQUEST_FAILED");
      }
    });
}

export function ApiError(message, data, status) {
  let response = null;
  let isObject = false;

  // We are trying to parse response
  try {
    response = JSON.parse(data);
    isObject = true;
  } catch (e) {
    response = data;
  }

  this.response = response;
  this.message = message;
  this.status = status;
  this.toString = function () {
    return `${this.message}\nResponse:\n${
      isObject ? JSON.stringify(this.response, null, 2) : this.response
    }`;
  };
}


// When encoding Javascript Date to JSON drop the timezone, because the players
// should player content at local time identical to browser time.
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function() {
  return new Date(this.getTime() - this.getTimezoneOffset() * 60000).toISOString()
}


function dateParser(key, value) {
  if(key.endsWith("timestamp") && value !== null) {
    const date = new Date(value)
    if(!isNaN(date.getTime()))
      return date
  }

  return value
}


// See https://github.com/axios/axios/blob/master/lib/defaults.js
function transformResponse(data) {
  /*eslint no-param-reassign:0*/
  if(typeof data === 'string') {
    try {
      data = JSON.parse(data, dateParser);
    } catch (e) { /* Ignore */ }
  }
  return data;
}
