import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDialog } from './Dialog'
import { useAuth } from '../auth/Authentication'

import PropTypes from 'prop-types'

function useDataInterface(params) {
  const { t } = useTranslation()

  const {
    endPoint,
    disabled,
    autoUpdate,
    handleNewIds,
    omitRefreshDataOnNewIds,
    handleDeleteNotification,
    handleSaveEditNotification,
    externalAuth,
    externalData,
  } = params

  const dialog = useDialog()

  const auth = useAuth()
  const {
    authCall,
    showLoadingIndicator,
    hideLoadingIndicator,
    clientId
  } = externalAuth ? externalAuth : auth

  const [tableDataId, setTableDataId] = useState(0)
  const [tableData, setTableData] = useState([])

  const [searchText, setSearchText] = useState('')
  const handleSearchTextChange = useCallback(
    (event) => {
      event.persist()
      setSearchText(event.target.value)
    },
    [setSearchText]
  )

  // Table sorting
  const [sortParams, setSortParams] = useState({
    column: 'index',
    order: 'asc',
  })
  const sort = (data, params, sortStringFunction) => {
    const yes = params.order === 'asc' ? -1 : 1
    const no = -yes
    data.sort((a, b) => {
      const valA = sortStringFunction ? sortStringFunction(a) : a[params.column]
      const valB = sortStringFunction ? sortStringFunction(b) : b[params.column]

      if (typeof valA === 'string') {
        if (yes === -1) return valA.localeCompare(valB)
        else return valB.localeCompare(valA)
      } else {
        return valA < valB ? yes : no
      }
    })
  }
  const sortData = useCallback(
    (column, sortStringFunction) => {
      const order =
        sortParams.column === column
          ? sortParams.order === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc'
      const params = { column: column, order: order }
      setTableData((data) => {
        const sortData = [...data]
        sort(sortData, params, sortStringFunction)
        return sortData
      })
      setSortParams(params)
    },
    [sortParams]
  )

  // Called when authCall returns to make the incoming data available and
  // increment the data id.
  const updateTableData = useCallback(
    (data) => {
      if(data.constructor === Array) {
        //console.log("**** DataInterface updateTableData **** " + endPoint)
        data.forEach((item, index) => {
          item['index'] = index // Add index for each table row (also used for sorting)
          item['_id'] = item.id // ATTEMPTED HACK TO FIX CHANGING id
        })
        sort(data, sortParams)
        setTableData(data)
        setTableDataId(new Date().getTime())
      } else {
        dialog.showModal(t('info.unexpected_error', { error: data.error }))
      }
    },
    // Disable warning for dependency on sortParams -
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTableData, setTableDataId]
  )

  const executeSaveEdit = useCallback(
    (options, callerMsg, responseHandler) => {
      showLoadingIndicator()
      authCall(endPoint, [], options).then((data) => {
        if(data.error === '') {
          let refreshData = true

          if(data.id !== undefined && handleNewIds) {
            handleNewIds(data)
            if(omitRefreshDataOnNewIds)
              refreshData = false
          }

          if(refreshData) {
            authCall(endPoint).then((data) => {
              updateTableData(data)
              hideLoadingIndicator()
              if(responseHandler)
                responseHandler()
              if(handleSaveEditNotification)
                handleSaveEditNotification()
            })
          } else {
            if(responseHandler)
              responseHandler()
            hideLoadingIndicator()
          }
        } else {
          hideLoadingIndicator()
          console.log(callerMsg + ': ' + data.error)
          if(responseHandler)
            responseHandler(data)
          else
            dialog.showModal(t('info.unexpected_error', { error: data.error }))
        }
      })
    },
    [
      showLoadingIndicator,
      authCall,
      endPoint,
      handleNewIds,
      omitRefreshDataOnNewIds,
      updateTableData,
      hideLoadingIndicator,
      handleSaveEditNotification,
      dialog,
      t,
    ]
  )

  // To be called by parent component to save a newly created or edited data
  // item. In case of a newly created data item, some webservices return the
  // primary key of the database table in data.id. The parent component may
  // provide the function handleNewIds to process the returned response. In
  // addition, the boolean flag omitRefreshDataOnNewIds can be set to true
  // by the parent component to prevent the default reload of the data.
  const handleSaveEdit = useCallback(
    (method, rowData, responseHandler) => {
      const options = {
        method: method,
        headers: { 'content-type': 'application/json' },
        data: rowData,
      }
      return executeSaveEdit(options, 'DataInterface.handleSaveEdit', responseHandler)
    },
    [executeSaveEdit]
  )

  // To be called by parent component to duplicate an existing data item.
  const handleDuplicate = useCallback(
    (id) => {
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: { is_duplication: true, id: id, client_id: clientId },
      }
      executeSaveEdit(options, 'DataInterface.handleDuplicate')
    },
    [executeSaveEdit, clientId]
  )

  // To be called by parent component to delete an existing data item.
  const handleDelete = useCallback(
    (id, data) => {
      const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        data: { id: id },
      }
      if(data && data.state)
        options.data.state = data.state

      showLoadingIndicator()
      authCall(endPoint, [], options).then((data) => {
        if (data.error === '' && data.info) {
          dialog.showModal(t('info.' + data.info))
          hideLoadingIndicator()
        } else if (data.error === '') {
          authCall(endPoint).then((data) => {
            updateTableData(data)
            hideLoadingIndicator()
            if (handleDeleteNotification)
              handleDeleteNotification(data.length ? data[0].id : -1)
          })
        } else {
          hideLoadingIndicator()
          console.log('DataInterface.handleDelete: ' + data.error)
        }
      })
    },
    [
      showLoadingIndicator,
      authCall,
      endPoint,
      dialog,
      t,
      updateTableData,
      hideLoadingIndicator,
      handleDeleteNotification,
    ]
  )

  const refreshData = useCallback(() => {
    if (endPoint !== '' && !disabled)
      authCall(endPoint).then((data) => updateTableData(data))
  }, [endPoint, disabled, authCall, updateTableData])

  // Upon mount or change of endPoint, load the tableData.
  useEffect(() => {
    if (externalData)
      updateTableData(externalData)
    else if ((endPoint !== '' && !disabled) && (autoUpdate === undefined || autoUpdate === true))
      refreshData()
  }, [externalData, updateTableData, endPoint, disabled, autoUpdate, authCall, refreshData])

  return {
    dataId: tableDataId,
    data: tableData,
    handleDuplicate: handleDuplicate,
    handleDelete: handleDelete,
    searchText: searchText,
    handleSearchTextChange: handleSearchTextChange,
    handleSaveEdit: handleSaveEdit,
    sortData: sortData,
    sortProperty: sortParams.column,
    sortOrder: sortParams.order,
    refreshData,
  }
}

useDataInterface.propTypes = {
  endPoint: PropTypes.string,
}

export { useDataInterface }
